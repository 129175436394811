<template>
  <section :id="id" class="scroll-m-[180px]">
    <header class="mb-8 flex items-center justify-between">
      <div>
        <component
          :is="titleWrapper"
          v-if="title || $slots.title"
          class="group"
          :to="to"
        >
          <h2 class="text-2xl font-extrabold">
            <slot name="title">
              {{ title }}
            </slot>

            <slot name="append-title" />

            <app-icon
              v-if="to"
              class="ml-2 scale-90 transition group-hover:scale-100"
              icon="ph:arrow-circle-up-right"
            />
          </h2>
        </component>

        <p v-if="subtitle" class="mt-1 text-subtle">{{ subtitle }}</p>
      </div>

      <slot name="append-header" />
    </header>

    <slot />
  </section>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  title?: I18nMessage;
  subtitle?: I18nMessage;
  id?: string;
  to?: RouteLocationRaw;
}>();

const titleWrapper = computed(() =>
  properties.to ? resolveComponent("nuxt-link") : "div",
);
</script>
